@import "mixins";
@import "custom";
@import "variables";

$duration: 1000ms;

.enter {
  inline-size: 100%;
  position: absolute !important;
  transform: scale(1.1);
  z-index: 13;
}

.enterActive {
  position: absolute;
  transform: scale(1);
}

.enterDone {
  position: relative;
  z-index: 12;
}

.exit {
  z-index: 11;
}

.exitActive {
  position: absolute !important;
}
