@import "mixins";
@import "custom";
@import "variables";

$duration: 1000ms;

.enter {
  inline-size: 100%;
  position: absolute !important;
  transform: scale(1.1);
  z-index: 10;
}

.enterActive {
  position: absolute;
  transform: scale(1);
  transition:
    clip $duration ease-in-out,
    transform $duration ease-in-out;
}

.enterDone {
  position: relative;
}

.exit {
  clip: rect(0, 100vw, 100vh, 0);
  transform: scale(1);
  z-index: 11;
}

.exitActive {
  clip: rect(0, 0, 100vh, 0);
  position: absolute !important;
  transform: scale(1.1);
}
